<template>
    <div>
        <v-btn class="ma-2" rounded color="success" @click="down" outlined>
          <span> {{ $t("globals.down") }} </span>
          <v-icon>mdi-download</v-icon>
        </v-btn>
         <div
              v-if="showProg"
              class="text-center "
              style="margin-top:10px"
            >
              <!-- <h4>{{ textupload }}</h4> -->
              <v-progress-circular
                :rotate="360"
                :size="100"
                :width="10"
                :value="mm"
                color="teal"
              >
                {{ mm }}
              </v-progress-circular>
              
              <!-- <v-progress-linear rounded v-model="mm" height="25">
                <strong>{{ Math.ceil(mm) }}%</strong>
              </v-progress-linear> -->

              <v-divider class="ma-4"></v-divider>
             
            </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            mm :0,
            showProg:false,
        }
    },
    methods:{
    down: async function name() {
      this.showProg = true
      const config = {
          //Choose upload
          onUploadProgress: (event) => {
            this.mm = Math.round((event.loaded * 100) / event.total);
            if (this.mm == 100) {
              this.trys = true;
            }
          },
          //Or download
          onDownloadProgress: (event) => {
            this.mm = Math.round((event.loaded * 100) / event.total);
            if (this.mm == 100) {
              this.trys = true;
            }
          },
          headers: {
            Accept: "application/json,*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization",
            Authorization: "Bearer " + this.$store.getters.getToken,
            id: this.$store.getters.getUserId,
          },
        };
      await this.$http.get("/v/download", config)
      .then(async (data) => {
       const res =  await this.$http.get(`${data.data.down}`, {
          headers: {
           Authorization: "Bearer " + this.$store.getters.getToken,
            id: this.$store.getters.getUserId,
          },
        });
        
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
     var fileLink = document.createElement('a');
    
     fileLink.href = fileURL;
     fileLink.setAttribute('download', 'sp0mers-team.txt');
     document.body.appendChild(fileLink);
     
     fileLink.click();
        // alert(data.data.down)
        // http://localhost:3000/down/ffffff/ffffff2021-09-07.txt
      })
      .catch((err)=>{
        alert(err.message)
      })
    },
    }
}
</script>