<template>
  <div>
   
    <template>
      <div class="text-center dailog" @click="hideall">
        <div class="modal-content">
        
    <v-card>
                    <v-card-text>
                      {{ $t("scama.areyou") }}
                      <strong class="red--text">{{ data.email }}</strong>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="$emit('close')">
                        cancel
                      </v-btn>
                      <v-btn
                        color="success"
                        text
                        @click="deleteAcoount(data.id,itemsss)"
                      >
                        confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
         
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data","itemsss"],
  data() {
    return {
      dialog: true,
      isLoading: false
    };
  },
  methods: {
    
    deleteAcoount: function (param,iteme) {
      this.isLoading = true;
      
      this.$http
        .get("/v/del/" + param, {
          headers: {
            Authorization: "Bearer ".concat(this.$store.getters.getToken),
            id: this.$store.getters.getUserName,
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.$emit("romoveitem",iteme);
          this.$emit('close');
           this.$toastr("add", {
              title: "succefully",
              msg: "success delete account",
              timeout: 3000,
              position: "toast-top-left",
              type: "success",
              closeOnHover: true,
              clickClose: false,
            });
          return res;
        })
        .catch((err) => {
          this.isLoading = false;
           this.$toastr("add", {
              title: "Opps",
              msg: err.message,
              timeout: 3000,
              position: "toast-top-right",
              type: "error",
              closeOnHover: true,
              clickClose: false,
            });
          
        });
    },
  },
};
</script>
<style scoped>
.dailog {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal-content {
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
}
@media (max-width: 800px) {
  .modal-content {
    width: 80%;
  }
  .dailog {
    top: 50px;
  }
}
</style>